/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { omit } from "lodash";
import { $get, $post, $put } from "src/infra/http";
import { COLLECTOR_STATUS } from "./types";
const getCollectorDetail = createAsyncThunk("getClientDetail", async (collectorId) => await $get(`/collection-manager/collectors/${collectorId}`));
const updateCollectorDetail = createAsyncThunk("updateCollectorDetail", async (collectorInformation) => await $put(`/collection-manager/collectors/update/${collectorInformation.id}`, omit(collectorInformation, "id")));
const suspendCollector = createAsyncThunk("suspendCollector", async (collectorId) => await $put(`/collection-manager/collectors/change-status/${collectorId}`, {
    status: COLLECTOR_STATUS.SUSPENDED,
}));
const deleteCollector = createAsyncThunk("deleteCollector", async (collectorId) => await $put(`/collection-manager/collectors/change-status/${collectorId}`, {
    status: COLLECTOR_STATUS.DELETED,
}));
const restoreCollector = createAsyncThunk("restoreCollector", async (collectorId) => await $put(`/collection-manager/collectors/change-status/${collectorId}`, {
    status: COLLECTOR_STATUS.ONBOARDED,
}));
const getAllCollector = createAsyncThunk("getAllCollector", async () => await $get(`/collection-manager/collectors/lists`));
// 콜렉터 디테일 가져옴
const resendInviteCollector = createAsyncThunk("resendInviteCollector", async (collectorId) => await $get(`/collection-manager/collectors/re-invite/${collectorId}`));
const inviteCollector = createAsyncThunk("inviteCollector", async (collectorInformation) => await $post("/collection-manager/collectors/invite", collectorInformation));
const getCollectAssistConfiguration = createAsyncThunk("getCollectAssistConfiguration", async () => await $get("/collection-manager/collectors/app-configuration"));
const updateCollectAssistConfiguration = createAsyncThunk("updateCollectAssistConfiguration", async (appConfiguration) => await $put("/collection-manager/collectors/app-configuration", {
    testConfiguration: appConfiguration,
}));
const getCollectorCredential = createAsyncThunk("getCollectorCredential", async (collectorId) => await $get(`/collection-manager/pm/collect-assist/credentials/${collectorId}`));
// Profile에서 accreditation 가져옴
const getAllExpirationManagement = createAsyncThunk("getAllExpirationManagement", async () => await $get(`/collection-manager/pm/collect-assist/expirationManagement`));
// collectionorganisation에 포함된 전체collector의 accreditiOn을 가져옴
const updateCollectorCredential = createAsyncThunk("updateCollectorCredential", async (collectorCredential) => await $get(`/collection-manager/collectors/update/${collectorCredential.id}`, collectorCredential));
const getListCollectorCredentials = createAsyncThunk("getListCollectorCredentials", async (collectorId) => await $get(`/collection-manager/collectors/credentials/${collectorId}`));
const getCollectorCredentialAuditLog = createAsyncThunk("getCollectorCredentialAuditLog", async (collectorId) => await $get(`/collection-manager/collectors/${collectorId}/credentials/audit-log`));
export { getCollectorCredential, getAllExpirationManagement, updateCollectorCredential, getListCollectorCredentials, getCollectorCredentialAuditLog, getCollectorDetail, updateCollectorDetail, suspendCollector, deleteCollector, restoreCollector, getAllCollector, resendInviteCollector, inviteCollector, getCollectAssistConfiguration, updateCollectAssistConfiguration, };
