import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Dropdown, List, Menu, Popover, Space, Table, Typography } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CloseButtonIcon from "src/assets/svg/job-management/close-button-icon.svg";
import ViewCollectorIcon from "src/assets/svg/job-management/view-collector-icon.svg";
import { SAPaginatedTableContainer } from "src/components/Form";
import SAStatusButton from "src/components/Form/SAStatusButton";
import { mainAppColours } from "src/constants";
import { DefaultRequestPagination } from "src/infra/pagination";
import { archiveJob, getListActiveJobs } from "src/pages/JobManagement/store/action";
import { getApplicationInformation, selectDateWithSetupTime } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { OnsiteJobAllocationStatus, OnsiteJobStatus, OnsiteJobType, } from "../store/types";
import { DisplayedJobStatuses, DisplayOnsiteJobAllocationStatus, OnsiteJobClientStatusLabel, OnsiteJobServiceTypeName, } from "../typing";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import { selectListActiveJobs } from "../store/selector";
const JobTable = () => {
    const parentLocation = useLocation();
    const [open, setOpen] = useState(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const activeJobs = useAppSelector(selectListActiveJobs);
    const appInformation = useAppSelector(getApplicationInformation);
    const { data, pagingMeta } = activeJobs;
    const [filters, setFilters] = useState(DefaultRequestPagination);
    const closePopover = () => {
        setOpen(null);
    };
    const handleOpenPopoverChange = (openId) => {
        setOpen(openId);
    };
    const fetchData = async (filter) => {
        dispatch(setIsLoading(true));
        await dispatch(getListActiveJobs(filter));
        dispatch(setIsLoading(false));
    };
    return (_jsx(SAPaginatedTableContainer, { pagingMeta: filters, totalItems: pagingMeta.totalItem, onPageSizeChange: (size) => setFilters((prev) => ({
            ...prev,
            limit: size,
        })), children: _jsxs(Table, { dataSource: data, rowKey: "onsiteJobId", pagination: {
                current: pagingMeta?.page,
                pageSize: pagingMeta?.limit,
                total: pagingMeta?.totalItem ?? 0,
                onChange: (page, limit) => {
                    setFilters((pre) => ({ ...pre, page, limit }));
                    fetchData({ page, limit });
                },
            }, children: [_jsx(Table.Column, { title: "No.", sorter: true, render: (_, __, index) => (pagingMeta.page - 1) * pagingMeta.limit + index + 1 }), _jsx(Table.Column, { title: "Date", dataIndex: "startAt", sorter: true, render: (startAt) => selectDateWithSetupTime(startAt, appInformation?.collectionOrganization.timeFormat) }), _jsx(Table.Column, { title: "Client", dataIndex: "clientName", sorter: true }), _jsx(Table.Column, { title: "Authorised Rep", dataIndex: "clientRepresentative", sorter: true }), _jsx(Table.Column, { title: "Location", dataIndex: "location", sorter: true }), _jsx(Table.Column, { title: "Service", dataIndex: "serviceType", sorter: true, render: (serviceType) => OnsiteJobServiceTypeName[serviceType] }), _jsx(Table.Column, { title: "Job Type", sorter: true, render: (_, record) => (_jsxs(Space, { direction: "vertical", children: [_jsx(Typography.Text, { children: OnsiteJobClientStatusLabel[record.clientStatus] }), record.isRemoteWork && _jsx(Typography.Text, { children: "Remote Work" })] })) }), _jsx(Table.Column, { title: "Call Out Job", dataIndex: "type", align: "center", sorter: true, render: (type) => (type === OnsiteJobType.Callout ? "Call Out" : null) }), _jsx(Table.Column, { title: "Status", dataIndex: "status", align: "center", sorter: true, render: (status) => (_jsx(SAStatusButton, { color: DisplayedJobStatuses[status]?.color, children: DisplayedJobStatuses[status]?.label })) }), _jsx(Table.Column, { title: "Collector", dataIndex: "collectors", align: "center", render: (_, record) => (_jsx(CustomPopover, { content: _jsx(CardContent, { title: _jsxs(CardHeader, { children: [_jsx(Typography.Title, { level: 5, children: "Collectors" }), _jsx(Button, { type: "text", icon: _jsx(CloseButtonIcon, {}), onClick: closePopover })] }), children: _jsx(List, { itemLayout: "horizontal", dataSource: record.collectors, renderItem: (collector, index) => {
                                    const status = DisplayOnsiteJobAllocationStatus[collector.status];
                                    return (_jsxs(List.Item, { children: [_jsx(List.Item.Meta, { title: _jsxs(CollectorNameText, { status: collector.status, children: [collector.firstName, " ", collector.lastName] }) }), _jsx(SAStatusButton, { color: status?.color, children: status?.label })] }, index));
                                } }) }), trigger: "click", placement: "bottomRight", open: open === record.onsiteJobId, onOpenChange: () => handleOpenPopoverChange(record.onsiteJobId), children: _jsx(Button, { type: "text", icon: _jsx(ViewCollectorIcon, {}) }) })) }), _jsx(Table.Column, { title: "", width: 55, render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(StyledMenu, { children: [_jsx(StyledMenuItem, { onClick: () => {
                                        navigate(`${parentLocation.pathname}/${record.onsiteJobId}/${record.type === OnsiteJobType.Normal ? "normal" : "call-out"}`);
                                    }, children: record.status === OnsiteJobStatus.Planned ||
                                        record.status === OnsiteJobStatus.Draft
                                        ? "Manage Job"
                                        : "View Details" }, "ManageJob"), record.status === OnsiteJobStatus.Cancelled ||
                                    (record.status === OnsiteJobStatus.Draft && (_jsx(StyledMenuItem, { onClick: () => {
                                            record.status !== OnsiteJobStatus.Archived &&
                                                dispatch(archiveJob(record.onsiteJobId));
                                        }, children: _jsx(ArchiveSpan, { children: "Delete" }) }, "Delete")))] }), trigger: ["click"], children: _jsx(ICMenuOptions, {}) })) }, "action")] }) }));
};
export default JobTable;
const CustomPopover = styled(Popover) `
  div.ant-popover-inner-content {
    padding: 0 !important;
    border: 0.1rem solid #1a8cff !important;
  }
`;
const CardContent = styled(Card) `
  width: 20rem;
  .ant-card-head {
    border-bottom: 0.2rem solid #01b4d2;
  }
`;
const CardHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    color: #00b4d1;
  }
`;
const CollectorNameText = styled(Typography.Text) `
  color: ${(props) => props.status === OnsiteJobAllocationStatus.Rejected ? "#FD0000" : "#000000"};
`;
const StyledMenu = styled(Menu) `
  border: 1px solid ${mainAppColours.MID_BLUE};
  width: 150px;
`;
const StyledMenuItem = styled(Menu.Item) `
  &:not(:first-child) {
    border-top: 1px solid #adb3bc;
    width: 80%;
    margin: 0 auto;
  }
`;
const ArchiveSpan = styled.span `
  display: flex;
  justify-content: flex-end;
`;
