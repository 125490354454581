import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import TableSizeChanger from "../TableSizeChanger";
const SAPaginatedTableContainer = ({ children, pagingMeta, totalItems = 0, onPageSizeChange, }) => (_jsxs(TableContainer, { children: [children, _jsx(TableFooterContainer, { "$hidden": !totalItems, children: _jsx(TableSizeChanger, { value: pagingMeta.limit ?? 10, onChange: (size) => {
                    onPageSizeChange(size);
                } }) })] }));
export default SAPaginatedTableContainer;
const TableContainer = styled.div `
  width: 100%;
  position: relative;
  height: fit-content;

  .ant-pagination-options {
    display: none;
  }
  .ant-table-thead > tr > th {
    background-color: #e6f7fa;
  }
`;
const TableFooterContainer = styled.div `
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 5rem;
  bottom: 1rem;
  white-space: nowrap;
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
`;
