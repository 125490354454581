import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import CreateCallOutJob from "./CreateNewJob/CallOutJob";
import CreateNormalJob from "./CreateNewJob/NormalJob";
import { OnSiteApprovals } from "./OnSiteApprovals";
import OnSiteJobs from "./OnSiteJobs";
import UpdateCallOutJob from "./UpdateJob/CallOutJob";
import UpdateNormalJob from "./UpdateJob/NormalJob";
const JobManagementPage = () => (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "onsite-jobs", replace: true }) }), _jsx(Route, { path: "onsite-jobs", element: _jsx(OnSiteJobs, {}) }), _jsx(Route, { path: "onsite-jobs/create-normal", element: _jsx(CreateNormalJob, {}) }), _jsx(Route, { path: "onsite-jobs/create-call-out", element: _jsx(CreateCallOutJob, {}) }), _jsx(Route, { path: "approval", element: _jsx(OnSiteApprovals, {}) }), _jsx(Route, { path: "onsite-jobs/:onsiteJobId/normal", element: _jsx(UpdateNormalJob, {}) }), _jsx(Route, { path: "onsite-jobs/:onsiteJobId/call-out", element: _jsx(UpdateCallOutJob, {}) })] }) }));
export default JobManagementPage;
export const breadcrumbs = [
    {
        path: "/job-management",
        name: "Job Management",
        level: 1,
    },
    {
        path: "/onsite-jobs",
        name: "On-Site Jobs",
        level: 2,
    },
    {
        path: "/approval",
        name: "On-Site Approvals",
        level: 2,
    },
];
