const selectListActiveJobs = (state) => state.jobManagementPage.listActiveJobs;
const selectListClients = (state) => state.jobManagementPage.listClients;
const selectListCollectionPointDefaultData = (state) => state.jobManagementPage.listCollectionPointDefaultData;
const selectClientDetails = (state) => state.jobManagementPage.selectedClientDetails;
const selectCreatingJobDetails = (state) => state.jobManagementPage.jobSetupDetails;
const selectExistedJobDetail = (state) => state.jobManagementPage.existedJobData;
const selectedStartDate = (state) => state.jobManagementPage.startDate;
const selectedEndDate = (state) => state.jobManagementPage.endDate;
const selectedStatusTypes = (state) => state.jobManagementPage.selectedStatusType;
const selectCurrentCollectionPointDefaultData = (state) => {
    const { listCollectionPointDefaultData, jobSetupDetails } = state.jobManagementPage;
    return listCollectionPointDefaultData.find((data) => data.id === jobSetupDetails.data.collectionPointId);
};
const selectCurrentCollectionPoint = (state) => state.jobManagementPage.selectedCollectionPoint;
const selectDropdownClient = (state) => state.jobManagementPage.selectedClient;
const selectOnSiteJobId = (state) => state.jobManagementPage.selectedOnSiteJobId;
export { selectClientDetails, selectCreatingJobDetails, selectCurrentCollectionPoint, selectCurrentCollectionPointDefaultData, selectDropdownClient, selectedEndDate, selectedStartDate, selectedStatusTypes, selectExistedJobDetail, selectListActiveJobs, selectListClients, selectListCollectionPointDefaultData, selectOnSiteJobId, };
