import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import { Dialog, DialogTitle, DialogContent, Switch, Checkbox, TextField, Button, Typography, IconButton, FormControlLabel, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReminderFrequency } from "src/pages/PlatformManagementMenuPage/CollectAssistPage/store/types";
export const formatRemainingTime = (remainingDays) => {
    if (remainingDays < ReminderFrequency.Unactivated) {
        return "0 day";
    }
    if (remainingDays < ReminderFrequency.Year) {
        const dayLabel = remainingDays === 0 || remainingDays === 1 ? "day" : "days";
        return `${remainingDays} ${dayLabel}`;
    }
    const years = Math.floor(remainingDays / ReminderFrequency.Year);
    const days = remainingDays % ReminderFrequency.Year;
    const yearLabel = years === 1 ? "year" : "years";
    const dayLabel = days === 0 || days === 1 ? "day" : "days";
    return `${years} ${yearLabel} ${days} ${dayLabel}`;
};
function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}
export const CredentialReminderSetting = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [photoIdEnabled, setPhotoIdEnabled] = useState(true);
    const [photoIdDays, setPhotoIdDays] = useState([ReminderFrequency.FortyFiveDays]);
    const [accreditationEnabled, setAccreditationEnabled] = useState(true);
    const [accreditationDays, setAccreditationDays] = useState([
        ReminderFrequency.FortyFiveDays,
    ]);
    const [industryEnabled, setIndustryEnabled] = useState(true);
    const [industryDays, setIndustryDays] = useState([ReminderFrequency.FortyFiveDays]);
    const [originalEmailList, setOriginalEmailList] = useState([
        "servicemanager@email.com",
    ]);
    const [emailList, setEmailList] = useState(originalEmailList);
    const isEmailModified = useMemo(() => JSON.stringify(emailList) !== JSON.stringify(originalEmailList), [emailList, originalEmailList]);
    const handleDaysChange = (daysArray, setDaysArray, dayValue) => {
        if (daysArray.includes(dayValue)) {
            setDaysArray(daysArray.filter((d) => d !== dayValue));
        }
        else {
            setDaysArray([...daysArray, dayValue]);
        }
    };
    const handleAddEmail = () => {
        setEmailList([...emailList, ""]);
    };
    const handleRemoveEmail = (index) => {
        if (emailList.length > 1) {
            const newList = [...emailList];
            newList.splice(index, 1);
            setEmailList(newList);
        }
    };
    const handleUpdateEmail = (index, value) => {
        const newList = [...emailList];
        newList[index] = value;
        setEmailList(newList);
    };
    const handleCancelEmails = () => {
        setEmailList(originalEmailList);
    };
    const handleSaveEmails = () => {
        if (emailList.some((email) => !isValidEmail(email))) {
            alert("This is a wrong email format");
            return;
        }
        setOriginalEmailList(emailList);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ActivatedOptionWithPink, { onClick: handleOpen, style: { pointerEvents: "none" }, children: [_jsx(MiddleIcon, { src: "/assets/svg/ic-renewal-reminder.svg", alt: "ic-renewal-reminder" }), _jsx(PinkTypography, { children: "Renewal Reminder" })] }), _jsxs(Dialog, { open: open, onClose: handleClose, fullWidth: true, maxWidth: "sm", children: [_jsxs(DialogHeader, { children: [_jsxs(DialogTitle, { sx: { flex: 1 }, children: [_jsx(TitleIcon, { src: "/assets/svg/ic-renewal-reminder.svg", alt: "ic-renewal-reminder" }), "Renewal Reminder Notification Settings"] }), _jsx(IconButton, { onClick: handleClose, sx: { position: "absolute", right: 8, top: 8 }, children: _jsx(CloseIcon, {}) })] }), _jsx(Divider, {}), _jsxs(DialogContent, { children: [_jsx(NotifyInfo, { children: "Notifications will be sent to your Collection Manager Inbox" }), _jsxs(ReminderSection, { children: [_jsx(FormControlLabel, { label: "", control: _jsx(Switch, { checked: photoIdEnabled, onChange: () => setPhotoIdEnabled((prev) => !prev) }) }), _jsxs("div", { children: [_jsx(Typography, { variant: "subtitle1", fontWeight: "bold", children: "Photo ID Renewal Reminder" }), _jsxs(SubText, { children: ["Remind ", _jsxs(BlueSpan, { children: [JSON.stringify(photoIdDays.sort()), " days"] }), " before the expiry date"] }), _jsx(CheckboxGroup, { children: [
                                                    ReminderFrequency.FifteenDays,
                                                    ReminderFrequency.ThirtyDays,
                                                    ReminderFrequency.FortyFiveDays,
                                                    ReminderFrequency.SixtyDays,
                                                ].map((val) => (_jsxs("label", { style: { cursor: "pointer" }, children: [_jsx(Checkbox, { checked: photoIdDays.includes(val), onChange: () => handleDaysChange(photoIdDays, setPhotoIdDays, val) }), val, " days"] }, val))) })] })] }), _jsxs(ReminderSection, { children: [_jsx(FormControlLabel, { label: "", control: _jsx(Switch, { checked: accreditationEnabled, onChange: () => setAccreditationEnabled((prev) => !prev) }) }), _jsxs("div", { children: [_jsx(Typography, { variant: "subtitle1", fontWeight: "bold", children: "Accreditation Renewal Reminder" }), _jsxs(SubText, { children: ["Remind ", _jsxs(BlueSpan, { children: [JSON.stringify(accreditationDays.sort()), " days"] }), " before the expiry date"] }), _jsx(CheckboxGroup, { children: [
                                                    ReminderFrequency.FifteenDays,
                                                    ReminderFrequency.ThirtyDays,
                                                    ReminderFrequency.FortyFiveDays,
                                                    ReminderFrequency.SixtyDays,
                                                ].map((val) => (_jsxs("label", { style: { cursor: "pointer" }, children: [_jsx(Checkbox, { checked: accreditationDays.includes(val), onChange: () => handleDaysChange(accreditationDays, setAccreditationDays, val) }), val, " days"] }, val))) })] })] }), _jsxs(ReminderSection, { children: [_jsx(FormControlLabel, { label: "", control: _jsx(Switch, { checked: industryEnabled, onChange: () => setIndustryEnabled((prev) => !prev) }) }), _jsxs("div", { children: [_jsx(Typography, { variant: "subtitle1", fontWeight: "bold", children: "Industry Card Renewal Reminder" }), _jsxs(SubText, { children: ["Remind ", _jsxs(BlueSpan, { children: [JSON.stringify(industryDays.sort()), " days"] }), " before the expiry date"] }), _jsx(CheckboxGroup, { children: [
                                                    ReminderFrequency.FifteenDays,
                                                    ReminderFrequency.ThirtyDays,
                                                    ReminderFrequency.FortyFiveDays,
                                                    ReminderFrequency.SixtyDays,
                                                ].map((val) => (_jsxs("label", { style: { cursor: "pointer" }, children: [_jsx(Checkbox, { checked: industryDays.includes(val), onChange: () => handleDaysChange(industryDays, setIndustryDays, val) }), val, " days"] }, val))) })] })] })] }), _jsx(Divider, {}), _jsxs(AdditionalEmailSection, { children: [_jsx(TitleIcon, { src: "/assets/svg/ic-mail.svg", alt: "ic-mail" }), _jsxs("div", { style: { width: "100%" }, children: [_jsxs("div", { style: { width: "100%", marginBottom: "8px" }, children: [_jsx(Typography, { variant: "subtitle1", sx: { fontWeight: "bold" }, children: "Send an Additional Reminder Email to:" }), _jsx(XSmallButton, { variant: "outlined", size: "small", disabled: !isEmailModified, onClick: handleCancelEmails, children: "Cancel" }), _jsx(XSmallButton, { variant: "contained", size: "small", color: "primary", disabled: !isEmailModified, onClick: handleSaveEmails, children: "Save" })] }), emailList.map((email, idx) => (_jsxs(FlexRow, { children: [_jsx(TextField, { value: email, onChange: (e) => handleUpdateEmail(idx, e.target.value), variant: "outlined", size: "small", sx: { flex: 1 } }), _jsx(MinusButton, { disabled: emailList.length <= 1, onClick: () => handleRemoveEmail(idx), children: "-" })] }, idx))), _jsx(AddButton, { onClick: handleAddEmail, children: "+" })] })] })] })] }));
};
const ActivatedOptionWithPink = styled.div `
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: #ffe5e5;
  border-radius: 15px;
  color: inherit;
  padding: 3px 8px;
  cursor: pointer;
  font-size: 6px;
`;
const MiddleIcon = styled.img `
  height: 20px;
`;
const PinkTypography = styled(Typography)(() => ({
    fontSize: "8px",
}));
const DialogHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TitleIcon = styled.img `
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
const NotifyInfo = styled(Typography) `
  text-align: center;
  color: #1a8cff;
  margin-bottom: 12px;
`;
const ReminderSection = styled.div `
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: #f7f7f7;
`;
const SubText = styled(Typography) `
  font-size: 14px;
  margin-top: 4px;
`;
const BlueSpan = styled.span `
  color: #1a8cff;
`;
const CheckboxGroup = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
`;
const AdditionalEmailSection = styled.div `
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin: 0 24px 16px 24px;
`;
const FlexRow = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;
const MinusButton = styled.button `
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 1px solid #888;
  border-radius: 8px;
  background-color: #fff;
`;
const AddButton = styled.button `
  margin-top: 4px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 1px solid #888;
  border-radius: 8px;
  background-color: #fff;
`;
const XSmallButton = styled(Button) `
  padding: 2px 8px;
  height: 26px;
  font-size: 0.75rem;
  border-radius: 4px;
`;
