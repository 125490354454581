import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, message, Select, Table, Typography } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import SAStatusButton from "src/components/Form/SAStatusButton";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { OnsiteJobAllocationStatus } from "../store/types";
import { DisplayOnsiteJobAllocationStatus } from "../typing";
const AssignCollectorTable = ({ availableCollectors, selectedCollectorIds, updateCollector, }) => {
    const selectedCollectors = [
        ...(availableCollectors?.filter((collector) => selectedCollectorIds.includes(collector.id)) ??
            []),
        ...selectedCollectorIds
            .filter((collectorId) => collectorId === "")
            .map(() => ({
            id: "",
            firstName: "",
            lastName: "",
            status: null,
        })),
    ];
    const dataSource = selectedCollectors.map((selectedCollector, index) => {
        const collector = availableCollectors?.find((col) => col.id === selectedCollector.id);
        const status = collector?.status ?? OnsiteJobAllocationStatus.Allocated;
        const filteredCollectors = availableCollectors?.filter((availableCollector) => !selectedCollectorIds.includes(availableCollector.id)) ?? [];
        if (collector) {
            filteredCollectors.push(collector);
        }
        let date = "";
        switch (status) {
            case OnsiteJobAllocationStatus.Allocated:
                date = collector?.allocatedAt ?? null;
                break;
            case OnsiteJobAllocationStatus.Accepted:
                date = collector?.acceptedAt ?? null;
                break;
            case OnsiteJobAllocationStatus.Rejected:
                date = collector?.rejectedAt ?? null;
                break;
        }
        const displayedDate = date ? moment(date) : moment();
        return {
            key: index,
            id: collector?.id || "",
            name: collector ? `${collector.firstName} ${collector.lastName}` : `Collector ${index + 1}`,
            status,
            statusLabel: DisplayOnsiteJobAllocationStatus[status]?.label || "",
            statusColor: DisplayOnsiteJobAllocationStatus[status]?.color || "default",
            date: displayedDate.format("DD MMM YYYY"),
            time: displayedDate.format("hh:mm A"),
            filteredCollectors,
        };
    });
    return (_jsxs(ContentContainer, { children: [_jsxs(Toolbar, { children: [_jsx(SAColumn, { md: 8, lg: 18, align: "flex-end", justify: "center", children: _jsx(Typography.Text, { children: "Number of Collectors" }) }), _jsx(SAColumn, { md: 8, lg: 3, children: _jsx(CustomInput, { value: selectedCollectorIds.length, disabled: true }) }), _jsx(SAColumn, { md: 8, lg: 3, children: _jsx(CustomButton, { type: "primary", onClick: () => {
                                if (selectedCollectorIds.length === 10) {
                                    message.error("Maximum number of allocated collectors is 10");
                                    return;
                                }
                                if (selectedCollectorIds.length && selectedCollectorIds.includes("")) {
                                    message.error("Please select a collector for the previous allocation");
                                    return;
                                }
                                const currentCollectors = [...selectedCollectorIds];
                                currentCollectors.push("");
                                updateCollector(currentCollectors);
                            }, children: "Add Collector" }) })] }), _jsxs(CustomTable, { dataSource: dataSource, bordered: false, pagination: false, rowKey: "key", children: [_jsx(Table.Column, { render: (_, __, index) => `Collector ${index + 1}` }), _jsx(Table.Column, { width: 300, render: (_, record, index) => (_jsx(Select, { notFoundContent: "No data or a collection point has not been selected yet", value: record.id, onChange: (value) => {
                                const currentId = value;
                                const newCollectors = selectedCollectorIds.map((currentCollectorId, createdIndex) => {
                                    if (index === createdIndex)
                                        return currentId;
                                    return currentCollectorId;
                                });
                                updateCollector(newCollectors);
                            }, fieldNames: { label: "label", value: "value" }, options: record.filteredCollectors?.map((collector) => ({
                                label: `${collector.firstName} ${collector.lastName}`,
                                value: collector.id,
                            })) })) }), _jsx(Table.Column, { title: "Status", dataIndex: "status", align: "center", render: (_, record) => (_jsx(SAStatusButton, { color: record.statusColor, children: record.statusLabel })) }), _jsx(Table.Column, { title: "Date", dataIndex: "date", align: "center" }), _jsx(Table.Column, { title: "Time", dataIndex: "time", align: "center" }), _jsx(Table.Column, { title: "Action", dataIndex: "action", align: "center", render: (_, __, index) => (_jsx(Button, { icon: _jsx(DeleteOutlined, {}), type: "text", onClick: () => {
                                const newCollectors = selectedCollectorIds.filter((_, idx) => idx !== index);
                                updateCollector(newCollectors);
                            } })) })] })] }));
};
export default AssignCollectorTable;
const ContentContainer = styled.div `
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const CustomInput = styled(Input) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
`;
const CustomButton = styled(Button) `
  width: 100%;
  background-color: #1a8cff;
  border: 0;
`;
const Toolbar = styled(SARow) `
  margin-bottom: 2rem;
`;
const CustomTable = styled(Table) `
  .ant-table-cell {
    background-color: #ffffff;
    border: none;
    border-bottom: 0.11rem solid rgb(1, 180, 210);
  }
  .ant-table-thead .ant-table-cell {
    color: rgb(1, 180, 210);
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    content: none;
  }
`;
