import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import Divider from "@mui/material/Divider";
// import EmailIcon from "src/assets/svg/ic-icon-mailto.svg";
export const CredentialNote = () => {
    const [isOpenNote, setIsOpenNote] = useState(false);
    const [oldNote, setOldNote] = useState("");
    const [note, setNote] = useState("");
    const hasSavedNote = oldNote.trim() !== "";
    const handleClose = () => {
        setIsOpenNote(false);
    };
    const handleChange = (e) => {
        setNote(e.target.value);
    };
    const isModified = note !== oldNote;
    const handleCancel = () => {
        setNote(oldNote);
        setIsOpenNote(false);
    };
    const handleSave = () => {
        setOldNote(note);
        setIsOpenNote(false);
    };
    const handleDelete = () => {
        setOldNote("");
        setNote("");
        setIsOpenNote(false);
    };
    const noteIcon = hasSavedNote ? (_jsx(BlueNoteIcon, { src: "/assets/svg/ic-credential-note-blue.svg", alt: "ic-credential-note-blue", onClick: () => setIsOpenNote(true) })) : (_jsx(WhiteNoteIcon, { src: "/assets/svg/ic-credential-note-white.svg", alt: "ic-credential-note-white", onClick: () => setIsOpenNote(true) }));
    return (_jsxs("div", { children: [noteIcon, _jsx(ModalOverlay, { isOpen: isOpenNote, children: _jsxs(ModalContent, { children: [_jsx(CloseButton, { onClick: handleClose, children: "\u00D7" }), _jsx("h2", { children: "Note" }), _jsx(Divider, {}), _jsx(TextArea, { value: note, onChange: handleChange }), _jsxs(Actions, { children: [_jsx(DeleteButton, { onClick: handleDelete, disabled: !hasSavedNote, children: _jsx("img", { style: { width: 20, height: 20 }, src: "/assets/svg/ic-delete2.svg", alt: "delete icon" }) }), _jsxs(ButtonContainer, { children: [_jsx(CancelButton, { onClick: handleCancel, disabled: !isModified, children: "Cancel" }), _jsx(SaveButton, { onClick: handleSave, disabled: !isModified, children: "Save" })] })] })] }) })] }));
};
const WhiteNoteIcon = styled.img `
  height: 20px;
  cursor: pointer;
`;
const BlueNoteIcon = styled.img `
  height: 20px;
  cursor: pointer;
`;
const ModalOverlay = styled.div `
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;
const ModalContent = styled.div `
  width: 400px;
  margin: 10% auto;
  background: white;
  padding: 20px;
  border-radius: 6px;
  position: relative;
`;
const CloseButton = styled.button `
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
`;
const TextArea = styled.textarea `
  width: 100%;
  height: 80px;
  resize: none;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #f3f3f3;
`;
const Actions = styled.div `
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const CancelButton = styled(Button) `
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: #f0f0f0;
    color: #000000;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const SaveButton = styled(Button) `
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: #40a9ff;
    color: #ffffff;
  }

  &:disabled {
    background-color: #40a9ff;
    color: #ffffff;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const ButtonContainer = styled.div `
  display: flex;
  gap: 10px;
`;
const DeleteButton = styled(Button) `
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover,
  &:focus {
    background-color: #f0f0f0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
