import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, DatePicker, Image, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Text } from "src/components/base";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { mainAppColours } from "src/constants";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { breadcrumbs } from "..";
import { ApprovalsDataTable } from "./ApprovalsData";
import { getListOnsiteApprovals } from "./store/action";
import { selectListOnsiteApprovals, selectedDate, selectedToday } from "./store/selector";
import { setSelectedDate, setSelectedToday } from "./store/slice";
export const OnSiteApprovals = () => {
    const dispatch = useAppDispatch();
    const onsiteApprovalsData = useAppSelector(selectListOnsiteApprovals);
    const isLoading = useAppSelector(selectIsLoading);
    const isSelectedToday = useAppSelector(selectedToday);
    const selectedDateValue = useAppSelector(selectedDate);
    const checkToday = (newDate) => {
        if (moment().format("DD/MM/YYYY") === newDate.format("DD/MM/YYYY")) {
            return true;
        }
        return false;
    };
    const handleDateChange = (date, _) => {
        dispatch(setSelectedToday(checkToday(moment(date))));
        dispatch(setSelectedDate(moment(date)));
    };
    const handleTodayClick = (status) => {
        dispatch(setSelectedToday(status));
        if (status) {
            const today = moment();
            dispatch(setSelectedDate(today));
        }
    };
    const handlePrevDay = () => {
        const newDate = moment(selectedDateValue).subtract(1, "day");
        dispatch(setSelectedToday(checkToday(newDate)));
        dispatch(setSelectedDate(newDate));
    };
    const handleNextDay = () => {
        const newDate = moment(selectedDateValue).add(1, "day");
        dispatch(setSelectedToday(checkToday(newDate)));
        dispatch(setSelectedDate(newDate));
    };
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListOnsiteApprovals());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [selectedDateValue]);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(React.Fragment, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(HeaderFilterWrapper, { children: _jsxs(Row, { align: "middle", children: [_jsxs(StyledCol, { span: 6, children: [_jsx(Image, { src: "/assets/svg/ic-approvals.svg", preview: false, height: 30 }), _jsx(Text, { name: "On-Site Approvals", className: "header-text" })] }), _jsxs(Col, { span: 18, className: "show-select", children: [_jsx(TodayButton, { onClick: () => handleTodayClick(true), selectedtoday: isSelectedToday.toString(), children: "Today" }), _jsxs(DatePickerDiv, { children: [_jsx(LeftOutlined, { onClick: handlePrevDay }), _jsx(DatePicker, { value: moment(selectedDateValue), format: "ddd, D MMM YYYY", onChange: handleDateChange, suffixIcon: null, allowClear: false }), _jsx(RightOutlined, { onClick: handleNextDay })] })] })] }) }), _jsx(ApprovalsDataTable, { onsiteApprovalsData: onsiteApprovalsData })] }) }));
};
const HeaderFilterWrapper = styled.div `
  margin-bottom: 1rem;

  .header-text {
    margin-left: 10px;
    font-size: 1rem !important;
    color: #2f3337 !important;
  }

  .add-new {
    border: 1px solid ${mainAppColours.MID_BLUE};
    border-radius: "4px";
    color: #1a8cff;

    .add-icon {
      margin-right: 5px;
    }
  }

  .show-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  .ant-typography {
    font-size: 14px;
    color: #1a576f !important;
  }

  .bold-text {
    font-weight: 700;
  }

  .type-select {
    width: 15rem;
  }

  .ant-select-selector {
    border: 1px solid #adb3bc !important;
    border-radius: 4px !important;
    height: 32px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }

  .ant-divider {
    border-left: 2px solid #adb3bc !important;
    margin: 0;
    height: 1.5rem !important;
  }

  .ant-picker {
    border-radius: 4px !important;
    border: 1px solid #adb3bc !important;
  }
`;
const StyledCol = styled(Col) `
  display: flex;
  align-items: center;
`;
const TodayButton = styled(Button) `
  border-radius: 4px;
  background: ${({ selectedtoday }) => (selectedtoday === "true" ? "#1A8CFF" : "white")} !important;
  color: ${({ selectedtoday }) => selectedtoday === "true" ? "white" : "rgba(0, 0, 0, 0.65)"} !important;
  border: 1px solid #adb3bc !important;
`;
const DatePickerDiv = styled.div `
  border: 1px solid #adb3bc;
  border-radius: 5px;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;

  .ant-picker {
    border: none !important;
  }

  .ant-picker-input > input {
    padding-left: 1rem !important;
  }

  .anticon-left {
    margin-left: 10px;
  }

  .anticon-right {
    margin-right: 10px;
  }
`;
