const selectCurrentCollector = (state) => state.platformManagementPage.collectAssist.currentCollector;
const getListCollectors = (state) => state.platformManagementPage.collectAssist.listCollectors;
const getListCurrentCollectors = (state) => state.platformManagementPage.collectAssist.listCurrentCollectors;
// 이건현재 콜랙텅의 신상정보
const selectAppConfiguration = (state) => state.platformManagementPage.collectAssist.appConfiguration;
const selectedCollectorCredential = (state) => state.platformManagementPage.collectAssist.selectedCollectorCredential;
// 이건 개개인의 신상정보
const getListCollectorCredentials = (state) => state.platformManagementPage.collectAssist.listCollectorCredentials;
const getListExpirationManagement = (state) => state.platformManagementPage.collectAssist.listExpirationManagement;
export { selectCurrentCollector, getListCollectors, getListCurrentCollectors, selectAppConfiguration, selectedCollectorCredential, getListCollectorCredentials, getListExpirationManagement, };
