import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { deleteCollector, getAllCollector, getCollectAssistConfiguration, getCollectorCredential, getCollectorCredentialAuditLog, getCollectorDetail, getListCollectorCredentials, inviteCollector, resendInviteCollector, restoreCollector, suspendCollector, updateCollectAssistConfiguration, updateCollectorCredential, updateCollectorDetail, getAllExpirationManagement, } from "./action";
export const initialState = {
    currentCollector: {},
    listCollectors: [],
    listCurrentCollectors: [],
    appConfiguration: {},
    selectedCollectorCredential: {},
    listCollectorCredentials: [],
    listExpirationManagement: [],
    auditLog: [],
};
const collectAssistSlice = createSlice({
    name: "collectAssist",
    initialState,
    reducers: {
        setCurrentCollector(state, action) {
            state.currentCollector = action.payload;
        },
        setCurrentListCollector(state, action) {
            state.listCurrentCollectors = action.payload;
        },
        setSelectedCollectorCredential(state, action) {
            state.selectedCollectorCredential = action.payload;
        },
        setListCollectorCredentials(state, action) {
            state.listCollectorCredentials = action.payload;
        },
        setListExpirationManagement(state, action) {
            state.listExpirationManagement = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCollectorDetail.pending, (state) => {
            state.currentCollector = initialState.currentCollector;
        })
            .addCase(getCollectorDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collector information");
            }
            else {
                state.currentCollector = action.payload.data;
            }
        })
            .addCase(getCollectorDetail.rejected, () => {
            message.error("Failed to fetch collector information");
        })
            .addCase(updateCollectorDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to update collector information");
            }
            else {
                message.success("update collector information successfully");
            }
        })
            .addCase(updateCollectorDetail.rejected, () => {
            message.error("Failed to update collector information");
        })
            .addCase(suspendCollector.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to suspend collector information");
            }
            else {
                message.success("suspend collector information successfully");
            }
        })
            .addCase(suspendCollector.rejected, () => {
            message.error("Failed to suspend collector information");
        })
            .addCase(deleteCollector.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to delete collector information");
            }
            else {
                message.success("delete collector information successfully");
            }
        })
            .addCase(deleteCollector.rejected, () => {
            message.error("Failed to delete collector information");
        })
            .addCase(restoreCollector.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to restore collector information");
            }
            else {
                message.success("restore collector information successfully");
            }
        })
            .addCase(restoreCollector.rejected, () => {
            message.error("Failed to restore collector information");
        })
            .addCase(getAllCollector.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Get list collector failed");
            }
            else {
                state.listCollectors = action.payload.data.collectors;
                state.listCurrentCollectors = action.payload.data.collectors;
            }
        })
            .addCase(getAllCollector.rejected, () => {
            message.error("Failed to restore collector information");
        })
            .addCase(resendInviteCollector.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to Resend collector invitation");
            }
            else {
                message.success("Resend invitation for collector successfully");
            }
        })
            .addCase(resendInviteCollector.rejected, () => {
            message.error("Failed to Resend collector invitation");
        })
            .addCase(inviteCollector.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Invite collector failed");
            }
            else {
                message.success("Invite collector successfully");
            }
        })
            .addCase(inviteCollector.rejected, () => {
            message.error("Invite collector failed");
        })
            .addCase(getCollectAssistConfiguration.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Fail to get app configuration");
            }
            else {
                state.appConfiguration = action.payload.data;
            }
        })
            .addCase(getCollectAssistConfiguration.rejected, () => {
            message.error("Fail to get app configuration");
        })
            .addCase(updateCollectAssistConfiguration.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Fail to update app configuration");
            }
            else {
                message.success("Update app configuration successfully");
                state.appConfiguration = action.payload.data.testConfiguration;
            }
        })
            .addCase(updateCollectAssistConfiguration.rejected, () => {
            message.error("Fail to update app configuration");
        })
            .addCase(getCollectorCredential.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collector information");
            }
            else {
                state.selectedCollectorCredential = action.payload.data;
            }
        })
            .addCase(getCollectorCredential.rejected, () => {
            message.error("Failed to fetch collector information");
        })
            .addCase(updateCollectorCredential.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to update collector information");
            }
            else {
                message.success("update collector information successfully");
            }
        })
            .addCase(updateCollectorCredential.rejected, () => {
            message.error("Failed to update collector information");
        })
            .addCase(getListCollectorCredentials.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collector information");
            }
            else {
                state.listCollectorCredentials = action.payload.data;
            }
        })
            .addCase(getListCollectorCredentials.rejected, () => {
            message.error("Failed to fetch collector information");
        })
            .addCase(getCollectorCredentialAuditLog.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collector information");
            }
            else {
                state.auditLog = action.payload.data;
            }
        })
            .addCase(getCollectorCredentialAuditLog.rejected, () => {
            message.error("Failed to fetch collector information");
        })
            .addCase(getAllExpirationManagement.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch expiration management information");
            }
            else {
                state.listExpirationManagement = action.payload.data;
            }
        })
            .addCase(getAllExpirationManagement.rejected, () => {
            message.error("Failed to fetch expiration management information");
        });
    },
});
export const { setCurrentCollector, setCurrentListCollector, setSelectedCollectorCredential, setListCollectorCredentials, setListExpirationManagement, setResetStore, } = collectAssistSlice.actions;
export default collectAssistSlice.reducer;
