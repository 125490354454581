import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get, $post, $put } from "src/infra/http";
const getListActiveJobs = createAsyncThunk("getListActiveJobs", async (pagination, thunkApi) => {
    const state = thunkApi.getState().jobManagementPage;
    const queryString = stringify({
        collectionPointId: state.selectedCollectionPoint,
        clientId: state.selectedClient,
        selectedStatus: state.selectedStatusType,
        startAt: state.startDate?.toDate() || null,
        endAt: state.endDate?.toDate() || null,
        ...pagination,
    }, { skipNulls: true });
    return await $get(`/collection-manager/job-management/list?${queryString}`);
});
const getCollectionPointDefaultData = createAsyncThunk("getCollectionPointDefaultData", async () => await $get("/collection-manager/job-management/points"));
const getListClients = createAsyncThunk("getListClients", async () => await $get(`/collection-manager/job-management/clients`));
const getClientDetail = createAsyncThunk("job/getClientDetail", async (onsiteClientId) => await $get(`/collection-manager/job-management/client/${onsiteClientId}`));
const getClientDetailInitial = createAsyncThunk("getClientDetailInitial", async (onsiteClientId) => await $get(`/collection-manager/job-management/client/${onsiteClientId}`));
const createOnsiteJob = createAsyncThunk("createOnsiteJob", async (data) => await $post(`/collection-manager/job-management`, data));
const createDraftOnsiteJob = createAsyncThunk("createDraftOnsiteJob", async (data) => await $post(`/collection-manager/job-management/draft`, data));
const updateDraftOnsiteJob = createAsyncThunk("updateDraftOnsiteJob", async (data) => await $put(`/collection-manager/job-management/draft`, data));
const updateOnsiteJob = createAsyncThunk("updateOnsiteJob", async (data) => await $put(`/collection-manager/job-management`, data));
const cancelJob = createAsyncThunk("cancelJob", async (onsiteJobId) => await $get(`/collection-manager/job-management/cancel/${onsiteJobId}`));
const restoreJob = createAsyncThunk("restoreJob", async (onsiteJobId) => await $get(`/collection-manager/job-management/restore/${onsiteJobId}`));
const archiveJob = createAsyncThunk("archiveJob", async (onsiteJobId) => await $get(`/collection-manager/job-management/archive/${onsiteJobId}`));
const getJobDetail = createAsyncThunk("getJobDetail", async (onsiteJobId) => await $get(`/collection-manager/job-management/detail/${onsiteJobId}`));
export { archiveJob, cancelJob, createDraftOnsiteJob, createOnsiteJob, getClientDetail, getClientDetailInitial, getCollectionPointDefaultData, getJobDetail, getListActiveJobs, getListClients, restoreJob, updateDraftOnsiteJob, updateOnsiteJob, };
