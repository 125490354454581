import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, Select, MenuItem, Checkbox, OutlinedInput, } from "@mui/material";
import styled from "styled-components";
import { CredentialType } from "src/pages/PlatformManagementMenuPage/CollectAssistPage/store/types";
export const ALL_OPTION = CredentialType.AllOption;
export const INDIVIDUAL_OPTIONS = [
    CredentialType.PhotoId,
    CredentialType.Accreditation,
    CredentialType.IndustryCard,
];
const CredentialDropdown = ({ selectedOptions, onChange }) => {
    const handleChange = (event) => {
        let value = event.target.value;
        if (selectedOptions.includes(ALL_OPTION) && value.length > 1) {
            value = value.filter((item) => item !== ALL_OPTION);
        }
        if (!selectedOptions.includes(ALL_OPTION) && value.includes(ALL_OPTION)) {
            onChange([ALL_OPTION]);
            return;
        }
        if (selectedOptions.includes(ALL_OPTION) && !value.includes(ALL_OPTION)) {
            onChange(value);
            return;
        }
        if (value.length === INDIVIDUAL_OPTIONS.length) {
            onChange([ALL_OPTION]);
        }
        else {
            onChange(value);
        }
    };
    const renderSelectValue = (selected) => selected.includes(ALL_OPTION) ? ALL_OPTION : selected.join(", ");
    const isOptionSelected = (option) => {
        if (selectedOptions.includes(ALL_OPTION))
            return true;
        return selectedOptions.includes(option);
    };
    return (_jsx(StyledFormControl, { variant: "outlined", size: "small", children: _jsxs(Select, { multiple: true, value: selectedOptions, onChange: handleChange, input: _jsx(OutlinedInput, {}), renderValue: renderSelectValue, displayEmpty: true, MenuProps: {
                PaperProps: {
                    style: {
                        maxHeight: 300,
                        width: 250,
                    },
                },
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            }, children: [_jsxs(MenuItem, { value: ALL_OPTION, children: [_jsx(Checkbox, { checked: isOptionSelected(ALL_OPTION) }), ALL_OPTION] }), INDIVIDUAL_OPTIONS.map((option) => (_jsxs(MenuItem, { value: option, children: [_jsx(Checkbox, { checked: isOptionSelected(option) }), option] }, option)))] }) }));
};
const StyledFormControl = styled(FormControl) `
  width: 200px;
  background-color: white;

  & .MuiOutlinedInput-root {
    border-radius: 4px;
    font-size: 0.875rem;
    height: 40px;
    display: flex;
    align-items: center;
  }

  & .MuiSelect-select {
    padding: 10px 14px;
  }
`;
export default CredentialDropdown;
