import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import SADivider from "src/components/Form/SADivider";
const Heading = ({ startTitle, endTitle, startIcon, endIcon }) => (_jsxs(React.Fragment, { children: [_jsxs(HeadingContainer, { children: [_jsxs(TitleWrapper, { children: [_jsx(Typography.Title, { level: 5, children: startTitle }), startIcon && _jsx(IconWrapper, { children: startIcon })] }), endTitle && (_jsxs(TitleWrapper, { children: [_jsx(Typography.Title, { level: 5, children: endTitle }), endIcon && _jsx(IconWrapper, { children: endIcon })] }))] }), _jsx(SADivider, {})] }));
export default Heading;
const HeadingContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const TitleWrapper = styled.div `
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
`;
