import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { SADivider } from "./Form";
const ToggleButton = ({ isOpen, label, divider, ...buttonProps }) => (_jsxs(React.Fragment, { children: [_jsx(StyledButton, { ...buttonProps, children: isOpen ? (_jsxs(_Fragment, { children: ["Hide ", label, " ", _jsx(MinusOutlined, {})] })) : (_jsxs(_Fragment, { children: ["Show ", label, " ", _jsx(PlusOutlined, {})] })) }), divider && _jsx(SADivider, {})] }));
export default ToggleButton;
const StyledButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edeef0;
  border: 0;
`;
