export const COLLECTOR_STATUS = {
    INVITED: 1,
    ONBOARDED: 2,
    SUSPENDED: 3,
    DELETED: 4,
};
export const COLLECTOR_STATUS_TITLE = {
    [COLLECTOR_STATUS.INVITED]: "INVITED",
    [COLLECTOR_STATUS.ONBOARDED]: "ONBOARDED",
    [COLLECTOR_STATUS.SUSPENDED]: "SUSPENDED",
    [COLLECTOR_STATUS.DELETED]: "DELETED",
};
export var AppLogoutTime;
(function (AppLogoutTime) {
    AppLogoutTime[AppLogoutTime["ThreeMinutes"] = 3] = "ThreeMinutes";
    AppLogoutTime[AppLogoutTime["FiveMinutes"] = 5] = "FiveMinutes";
    AppLogoutTime[AppLogoutTime["TenMinutes"] = 10] = "TenMinutes";
    AppLogoutTime[AppLogoutTime["FifteenMinutes"] = 15] = "FifteenMinutes";
    AppLogoutTime[AppLogoutTime["Never"] = 0] = "Never";
})(AppLogoutTime || (AppLogoutTime = {}));
export var AlcoholMeasurementUnit;
(function (AlcoholMeasurementUnit) {
    AlcoholMeasurementUnit[AlcoholMeasurementUnit["g210L"] = 0] = "g210L";
    AlcoholMeasurementUnit[AlcoholMeasurementUnit["BAC"] = 1] = "BAC";
})(AlcoholMeasurementUnit || (AlcoholMeasurementUnit = {}));
export var CredentialStatus;
(function (CredentialStatus) {
    CredentialStatus[CredentialStatus["Valid"] = 1] = "Valid";
    CredentialStatus[CredentialStatus["Unvalid"] = 2] = "Unvalid";
    CredentialStatus[CredentialStatus["Expring"] = 3] = "Expring";
    CredentialStatus[CredentialStatus["Expried"] = 4] = "Expried";
})(CredentialStatus || (CredentialStatus = {}));
export var ReminderFrequency;
(function (ReminderFrequency) {
    ReminderFrequency[ReminderFrequency["Unactivated"] = 0] = "Unactivated";
    ReminderFrequency[ReminderFrequency["FifteenDays"] = 15] = "FifteenDays";
    ReminderFrequency[ReminderFrequency["ThirtyDays"] = 30] = "ThirtyDays";
    ReminderFrequency[ReminderFrequency["FortyFiveDays"] = 45] = "FortyFiveDays";
    ReminderFrequency[ReminderFrequency["SixtyDays"] = 60] = "SixtyDays";
    ReminderFrequency[ReminderFrequency["Year"] = 365] = "Year";
})(ReminderFrequency || (ReminderFrequency = {}));
export var CredentialType;
(function (CredentialType) {
    CredentialType["AllOption"] = "All Credentials";
    CredentialType["PhotoId"] = "Photo Id";
    CredentialType["Qualification"] = "Qualification";
    CredentialType["Accreditation"] = "Accreditation";
    CredentialType["IndustryCard"] = "Industry Card";
})(CredentialType || (CredentialType = {}));
